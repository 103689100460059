<template>
    <section>
        <v-row class="mb-5">
            <v-tabs color="primary darken-1" centered icons-and-text v-model="tab" show-arrows>
                <v-tab @click="$router.push({name: 'profileInfo'}, () => {})" active-class="primary lighten-1 white--text px-2">{{ $t('information') }} <v-icon class="ml-2">mdi-account</v-icon></v-tab>
                <v-tab @click="$router.push({name: 'profilePlans'}, () => {})" active-class="primary lighten-1 white--text px-2">{{ $t('plan_subscription') }} <v-icon class="ml-2">mdi-account-cash</v-icon></v-tab>
                <v-tab @click="$router.push({name: 'profilePassword'}, () => {})" active-class="primary lighten-1 white--text px-2">{{ $t('safety') }} <v-icon class="ml-2">mdi-form-textbox-password</v-icon></v-tab>
<!--                <v-tab @click="$router.push({name: 'profileTrusters'})" active-class="primary lighten-1 white&#45;&#45;text px-2">{{ $t('trusters') }} <v-icon class="ml-2">mdi-account-lock</v-icon></v-tab>-->
                <v-tab @click="$router.push({name: 'profileNotifications'}, () => {})" active-class="primary lighten-1 white--text px-2">{{ $t('notifications') }} <v-icon class="ml-2">mdi-bell</v-icon></v-tab>
<!--                <v-tab @click="$router.push({name: 'profileSubscriptions'}, () => {})" active-class="primary lighten-1 white&#45;&#45;text px-2">{{ $t('subscription_users') }} <v-icon class="ml-2">mdi-account-supervisor-circle-outline</v-icon></v-tab>-->
            </v-tabs>
        </v-row>
    </section>
</template>

<script>
export default {
    data(){
        return {
            tab: this.$route.meta.index
        }
    }
}
</script>